import React, { Component } from 'react'

import PenDraw from 'react-pen-draw'

class Pen extends Component {
   state={
    clear:0
  }
  render () {
    return (
      <div>
        {/* <button onClick={()=>{this.setState({clear:1})}}>clear</button> */}
        <PenDraw 
          clearScreen={this.state.clear}
          lineWidth={2}
          lineColor="#ff5500"
          style={{
            border:"2px solid",
            width:"2000px",
            height:"1000px",
            border:"none",
            position: "absolute",
            zIndex: "0"
          }}
        />
      </div>
    )
  }
}

export default Pen