import logo from "./logo.svg";
import React, { Component } from 'react'
import "./App.css";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import Vid from "./Vid"
import Pen from "./Pen"
import Slider from "./Slider"
import Modal from 'react-modal';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Kiroku from "./images/kiroku.png";
import Connetric from "./images/connetric.png";
import Clinic from "./images/clinic.png";
import Rehydro from "./images/rehydro.png";
import Camkix from "./images/camkix.png";
import Lumary from "./images/lumary.png";
import Cryptoworld from "./images/cryptoworld.png";
import SF from "./images/sf.png";
import Dreamlight from "./images/dream.gif";
import CWV from "./images/cwv.png";
import Startup from "./images/startup.png";
import Juneng from "./images/juneng.png";
import MurloVideo from "./images/murlocrop.mp4";
import Warmchains from "./images/warmchains.png";
import Corner from "./images/corner.svg";

const handleDragStart = (e) => e.preventDefault();

const items = [
  <><div className="ImageTag">Showreel</div><img src={CWV}  onDragStart={handleDragStart} role="presentation" className="SliderImage" /></>,
  <img src={Juneng}  onDragStart={handleDragStart} role="presentation" className="SliderImage" />,
  <img src={Kiroku}  onDragStart={handleDragStart} role="presentation" className="SliderImage" />,
  <img src={Camkix}  onDragStart={handleDragStart} role="presentation" className="SliderImage" />,
  <img src={Dreamlight}  onDragStart={handleDragStart} role="presentation" className="SliderImage" />,
  <img src={Warmchains} onDragStart={handleDragStart} role="presentation" className="SliderImage" />,
];


function App() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  

    return (
      <>
      <img className="TopLeftCorner" src={Corner} />
      <img className="TopRightCorner" src={Corner} />
      <img className="BottomLeftCorner" src={Corner} />
      <img className="BottomRightCorner" src={Corner} />
      <Pen/>
    <div className="App">
    <div className="Nav">
      <p>HÉSO</p>
      <button onClick={openModal}>Contact</button>
    </div>
    <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="ModalStyle"
        contentLabel="Example Modal"
      >
        <p>Contact us</p>
        <div className="ButtonContainer">
      <button>hello@heso.io</button>
      <button>+44 (0) 7895695169</button>
      </div>
      <p>1 St Katharine's Way, London E1W 1UN
</p>
      </Modal>
    <div className="AppInner">
    {/* <Vid/> */}
    <div className="Move">
      <Draggable grid={[16, 16]}>
            <Resizable grid={[16, 16]}>
      <p>
      HÉSO is a design and technology studio that brings a full-service creative solution for Web3 companies.
      </p>
      </Resizable>
      </Draggable>
      </div>
      <Draggable grid={[16, 16]}>
            <Resizable grid={[16, 16]}>
      <p>
      By combining design, development, photography, video, and strategy, we help clients create hybrid worlds that exist in both physical and digital spaces.
      </p>
      </Resizable>
      </Draggable>
      <Draggable grid={[16, 16]}>
            <Resizable grid={[16, 16]}>
      <p>
      HÉSO is a working paradigm designed for flexibility and openness. It is jointly owned and has a horizontal structure.
      </p>
      </Resizable>
      </Draggable>
      {/* <Draggable grid={[16, 16]}>
            <Resizable grid={[16, 16]}>
      <p>
      We think in multiple languages and time zones, harnessing an evolving roster of disciplines to conceive and generate immersive experiences beyond what is possible alone
      </p>
      </Resizable>
      </Draggable> */}
      {/* <div className="ButtonContainer">
      <button>Anton Usikov</button>
      <button>Archie Hicklin</button>
      <button>Benny Day</button>
      <button>Luuc Verbuagh</button>
      <button>ND-X</button> */}
      {/* </div> */}
      {/* <br></br>
      <Slider/>
      <br></br> */}
      {/* <AliceCarousel mouseTracking items={items} disableButtonsControls={true} />
      <Draggable grid={[16, 16]}>
            <Resizable grid={[16, 16]}>
      <p>
      HÉSO is mutually owned and horizontally structured: a working model intended for range and transparency.
      </p>
      </Resizable>
      </Draggable>
      <Draggable grid={[16, 16]}>
            <Resizable grid={[16, 16]}>
      <p>
      We think in multiple languages and time zones, harnessing an evolving roster of disciplines to conceive and generate immersive experiences beyond what is possible alone
      </p>
      </Resizable>
      </Draggable> */}
    </div>
    </div>
    </>
     );
    }

    export default App;
