import React from 'react';
import Draggable from "react-draggable";
import Video from "./video.mp4";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import "./App.css";
import Kiroku from "./images/kiroku.png";
import Connetric from "./images/connetric.png";
import Clinic from "./images/clinic.png";
import Rehydro from "./images/rehydro.png";
import Camkix from "./images/camkix.png";
import Lumary from "./images/lumary.png";
import Cryptoworld from "./images/cryptoworld.png";
import SF from "./images/sf.png";
import Dreamlight from "./images/dream.gif";
import CWV from "./images/cwv.png";
import Startup from "./images/startup.png";
import Juneng from "./images/juneng.png";
import MurloVideo from "./images/murlocrop.mp4";
import Warmchains from "./images/warmchains.png";

const handleDragStart = (e) => e.preventDefault();

const items = [
    <><img src={CWV}  onDragStart={handleDragStart} role="presentation" className="SliderImage" /></>,
    <img src={Juneng}  onDragStart={handleDragStart} role="presentation" className="SliderImage" />,
    <img src={Kiroku}  onDragStart={handleDragStart} role="presentation" className="SliderImage" />,
    <img src={Camkix}  onDragStart={handleDragStart} role="presentation" className="SliderImage" />,
    <img src={Dreamlight}  onDragStart={handleDragStart} role="presentation" className="SliderImage" />,
    <img src={Warmchains} onDragStart={handleDragStart} role="presentation" className="SliderImage" />,
  ];


class Slider extends React.Component {
    state = {
        activeDrags: 0,
        deltaPosition: {
        x: 0, y: 0
    },
        controlledPosition: {
        x: -400, y: 200
        }
    };

  handleDrag = (e, ui) => {
    const {x, y} = this.state.deltaPosition;
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
    }
});
  };

  onStart = () => {
    this.setState({activeDrags: ++this.state.activeDrags});
  };

  onStop = () => {
    this.setState({activeDrags: --this.state.activeDrags});
  };
  onDrop = (e) => {
    this.setState({activeDrags: --this.state.activeDrags});
    if (e.target.classList.contains("drop-target")) {
      alert("Dropped!");
      e.target.classList.remove('hovered');
    }
  };
  onDropAreaMouseEnter = (e) => {
      if (this.state.activeDrags) {
      e.target.classList.add('hovered');
    }
  }
  onDropAreaMouseLeave = (e) => {
      e.target.classList.remove('hovered');
    }

  // For controlled component
  adjustXPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {x, y} = this.state.controlledPosition;
    this.setState({controlledPosition: {x: x - 10, y}});
};

adjustYPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {controlledPosition} = this.state;
    const {x, y} = controlledPosition;
    this.setState({controlledPosition: {x, y: y - 10}});
  };
  
  onControlledDrag = (e, position) => {
    const {x, y} = position;
    this.setState({controlledPosition: {x, y}});
};

onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
};

  render() {
    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
    const {deltaPosition, controlledPosition} = this.state;
    return (
        <div>
        <Draggable onStart={() => false} grid={[16, 16]}>
          <div>
            <div className="VideoDelta Toucher">{deltaPosition.x.toFixed(0)}, {deltaPosition.y.toFixed(0)}</div>
            <div className="ImageTag">Showreel</div>
            <AliceCarousel mouseTracking items={items} disableButtonsControls={true}></AliceCarousel>
          </div>
        </Draggable>
      </div>
    );
}
}

class RemWrapper extends React.Component {
  // PropTypes is not available in this environment, but here they are.
  // static propTypes = {
  //   style: PropTypes.shape({
  //     transform: PropTypes.string.isRequired
  //   }),
  //   children: PropTypes.node.isRequired,
  //   remBaseline: PropTypes.number,
  // }

  translateTransformToRem(transform, remBaseline = 16) {
    const convertedValues = transform.replace('translate(', '').replace(')', '')
      .split(',')
      .map(px => px.replace('px', ''))
      .map(px => parseInt(px, 10) / remBaseline)
      .map(x => `${x}rem`)
    const [x, y] = convertedValues

    return `translate(${x}, ${y})`
  }

  render() {
    const { children, remBaseline = 16, style } = this.props
    const child = React.Children.only(children)

    const editedStyle = {
      ...child.props.style,
      ...style,
      transform: this.translateTransformToRem(style.transform, remBaseline),
    }

    return React.cloneElement(child, {
       ...child.props,
       ...this.props,
       style: editedStyle
    })
  }
}


export default Slider;